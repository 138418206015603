.ck {
  &.ck-content {
    &:not(.ck-comment__input *) {
      height: 300px;
      overflow-y: auto;
    }

    ul,
    ul li {
      list-style-type: inherit;
    }

    ul,
    ol {
      /* Default user agent stylesheet */
      padding-left: var(--space-10);
    }
  }

  &.ck-editor__main > .ck-editor__editable:not(.ck-focused),
  &.ck-toolbar {
    border: none !important;
  }

  &.ck-editor__editable_inline {
    padding: 0 1em;
  }

  &.ck-editor {
    border: 1px solid var(--colors-border-color-a11y) !important;
    border-radius: var(--radii-md) !important;
    overflow: hidden !important;

    &.sc-field-error {
      border-color: var(--colors-red) !important;
      box-shadow: 0 0 0 1px var(--colors-red);
    }
  }

  &.ck-icon:not(.ck-dropdown__arrow):not(.ck-button__icon) {
    height: 22px !important;
    width: 22px !important;
    color: var(--colors-blackAlpha-600) !important;
  }

  &.ck-editor__main {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: revert !important;
    }
  }

  &.ck-button:not(.ck-disabled):hover,
  &a.ck-button:not(.ck-disabled):hover {
    background: var(--colors-blackAlpha-100) !important;
  }

  &.ck-button:not(.ck-disabled):active,
  &a.ck-button:not(.ck-disabled):active {
    background: var(--colors-blackAlpha-200) !important;
    box-shadow: none !important;
    border: 1px solid transparent !important;
    outline: none !important;
  }

  &.ck-button.ck-on,
  &a.ck-button.ck-on {
    color: var(--colors-primary-500) !important;
    background: var(--colors-primary-100) !important;
  }

  &.ck-button.ck-on:not(.ck-disabled):hover,
  &a.ck-button.ck-on:not(.ck-disabled):hover {
    background: var(--colors-primary-200) !important;
  }

  &.ck-button.ck-on:not(.ck-disabled):active,
  &a.ck-button.ck-on:not(.ck-disabled):active {
    background: var(--colors-primary-200) !important;
    box-shadow: none !important;
    border: 1px solid transparent !important;
    outline: none !important;
  }

  &.ck-button:active,
  &.ck-button:focus,
  &a.ck-button:active,
  &a.ck-button:focus {
    box-shadow: none !important;
    border: 1px solid transparent !important;
    outline: none !important;
  }

  &.ck-splitbutton > .ck-file-dialog-button.ck-button {
    border: unset !important;
  }

  &.ck-sticky-panel {
    .ck-sticky-panel__placeholder {
      display: none !important;
      height: auto !important;
    }
    .ck-sticky-panel__content_sticky {
      position: unset !important;
      width: 100% !important;
    }
  }
}
