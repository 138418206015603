.switch-button {
    background: var(--chakra-colors-white)!important;
    svg {
        z-index: 1;
    }
    &:hover{
        background: var(--colors-blackAlpha-100)!important;
    }
    &:active {
        background: var(--colors-blackAlpha-200)!important;
    }
    &:before {
        position: absolute;
        width: var(--sizes-full);
        height: var(--sizes-full);
        opacity: 0;
        transform: scale(.32);
        transition-duration: .3s;
        transition-property: transform,opacity;
        transition-timing-function: cubic-bezier(.2,0,0,1);
        border-radius: var(--radii-full);
        background: var(--colors-primary-100);
        content: "";
    }
    &.active {
        &:hover {
            background: var(--chakra-colors-white)!important;
        }
        &:active {
            background: var(--chakra-colors-white)!important;
        }
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}