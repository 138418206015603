.react-datepicker__close-icon.clear-button {
  margin-right: var(--space-8);

  &:after {
    background: var(--colors-gray-400);
    width: var(--sizes-3-5);
    height: var(--sizes-3-5);
    display: flex;
    justify-content: center;
    padding: 0;
  }
}

.react-datepicker-wrapper {
  .chakra-input {
    background: white;
  }
}
